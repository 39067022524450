import { useCallback } from "react";

import { useSearchParams } from "next/navigation";

import { useQueryStateContext } from "@/hooks/useQueryStateContext";

type QueryStateBuilderOptions = {
  history?: "push" | "replace";
  shallow?: boolean;
  scroll?: boolean;
};

type InternalQueryStateBuilderOptions = {
  history: "push" | "replace";
  shallow: boolean;
  scroll: boolean;
};

type QueryStateParser<T> = {
  parse: (query: string | null, defaultValue: T) => T;
  serialize: (value: T, defaultValue: T) => string | null;
};

export class QueryStateBuilder<T> {
  options: InternalQueryStateBuilderOptions;
  defaultValue: T;
  parser: QueryStateParser<T>;

  private defaultOptions = {
    history: "push" as const,
    shallow: true,
    scroll: false,
  };

  constructor(parser: QueryStateParser<T>, defaultValue: T, options: QueryStateBuilderOptions = {}) {
    this.options = { ...this.defaultOptions, ...options };
    this.defaultValue = defaultValue;
    this.parser = parser;
  }

  withDefault(defaultValue: T) {
    return new QueryStateBuilder(this.parser, defaultValue, this.options);
  }

  withOptions(options: QueryStateBuilderOptions) {
    return new QueryStateBuilder(this.parser, this.defaultValue, { ...this.options, ...options });
  }
}

const stringParser: QueryStateParser<string> = {
  parse: (value: string | null, defaultValue: string) => {
    if (!value) {
      return defaultValue;
    }

    return value;
  },
  serialize: (value: string, defaultValue: string) => {
    if (!value || value === defaultValue) {
      return null;
    }

    return value;
  },
};

const numberParser: QueryStateParser<number> = {
  parse: (value: string | null, defaultValue: number) => {
    if (value === null) {
      return defaultValue;
    }
    const num = Number(value);
    return isNaN(num) ? defaultValue : num;
  },
  serialize: (value: number, defaultValue: number) => {
    if (value === null || value === defaultValue) {
      return null;
    }
    return value.toString();
  },
};

const booleanParser: QueryStateParser<boolean> = {
  parse: (value: string | null, defaultValue: boolean) => {
    if (!value) {
      return defaultValue;
    }
    return value.toLowerCase() === "true" ? true : value.toLowerCase() === "false" ? false : defaultValue;
  },
  // eslint-disable-next-line sonarjs/no-identical-functions
  serialize: (value: boolean, defaultValue: boolean) => {
    if (value === null || value === defaultValue) {
      return null;
    }
    return value.toString();
  },
};

const stringLiteralParser = <T extends readonly string[]>(literals: T): QueryStateParser<T[number] | null> => ({
  parse: (value: string | null, defaultValue: T[number] | null) => {
    if (!value) {
      return defaultValue;
    }
    return literals.includes(value as T[number]) ? (value as T[number]) : defaultValue;
  },
  serialize: (value: T[number] | null, defaultValue: T[number] | null) => {
    if (!value || value === defaultValue) {
      return null;
    }
    return literals.includes(value) ? value : null;
  },
});

export const parseAsString = new QueryStateBuilder(stringParser, "");
export const parseAsNumber = new QueryStateBuilder(numberParser, 0);
export const parseAsBoolean = new QueryStateBuilder(booleanParser, false);
export const parseAsStringLiteral = <T extends readonly string[]>(literals: T) => {
  return new QueryStateBuilder(stringLiteralParser(literals), null);
};

/**
 * A hook for managing URL query parameters with type-safe parsing and serialization.
 *
 * @template T The type of the query parameter value
 * @param {string} key The query parameter key
 * @param {QueryStateBuilder<T>} builder A builder can be created using the `parseAsString`, `parseAsNumber`, `parseAsBoolean`, and `parseAsStringLiteral` functions
 * @returns {[T, (value: T | null) => void]} A tuple containing the current value and a setter function
 *
 * @example
 * // Basic string parameter
 * const [name, setName] = useQueryState('name', parseAsString);
 *
 * @example
 * // Number parameter with default value
 * const [count, setCount] = useQueryState('count', parseAsNumber.withDefault(10));
 *
 * @example
 * // Boolean parameter with custom router options
 * const [isOpen, setIsOpen] = useQueryState(
 *   'open',
 *   parseAsBoolean.withOptions({ history: 'replace' })
 * );
 *
 * @example
 * // String literal parameter with type safety
 * const [sort, setSort] = useQueryState(
 *   'sort',
 *   parseAsStringLiteral(['asc', 'desc'] as const)
 * );
 */
export const useQueryState = <T>(key: string, builder: QueryStateBuilder<T>) => {
  const searchParams = useSearchParams();

  const { enqueueRouterOperation } = useQueryStateContext();
  const {
    parser,
    defaultValue,
    options: { history, shallow, scroll },
  } = builder;

  const value = parser.parse(searchParams.get(key), defaultValue);

  const setValue = useCallback(
    (value: T | null) => {
      const newValue = parser.serialize(value ?? defaultValue, defaultValue);

      enqueueRouterOperation({
        key,
        value: newValue,
        history,
        shallow,
        scroll,
      });
    },
    [defaultValue, enqueueRouterOperation, history, key, parser, scroll, shallow]
  );

  return [value, setValue] as [T, (value: T | null) => void];
};
